<template>
  <div v-loading="loading">
    <el-select
      v-model="selected_filter"
      @change="updateEntityData"
      clearable
      filterable
      :placeholder="fieldPlaceholder"
      :size="size == 'mini' ? 'mini' : 'large'"
      collapse-tags
    >
      <el-option
        v-for="(temp, i) in allEntityDataList"
        :key="temp._id + '_' + i"
        :value="temp._id"
        :label="getLabel(temp, i)"
        >{{ getLabel(temp, i) }}
      </el-option>
    </el-select>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  props: ["fieldPlaceholder", "size", "isMultiple", "filter", "Data"],
  computed: {
    ...mapGetters("entities", [
      "getEntityDataByEntityId",
      "getEntityById",
      "getEntityRecordsForTable",
      "getEntitiesDataErrors",
    ]),
  },
  watch: {
    async field() {
      this.fetchEntityById(this.field.filter_field_entity_id);
    },
  },
  data() {
    return {
      currentEntity: null,
      loading: false,
      allEntityDataList: [],
      field: [],
      selected_filter: [],
    };
  },
  async mounted() {
    this.loading = true;
    await this.fetchEntityById(this.filter.filter_field_entity_id);
    this.loading = false;
  },
  methods: {
    updateEntityData(value) {
      this.selectedValue = value;
      this.filter.selected_filter = value;
      this.$emit("MulEntityFilterUpdated", {
        value: value,
        filter: this.filter,
      });
    },
    async fetchEntityById(entityId) {
      await this.$store.dispatch("entities/fetchEntityByIdForEntityField", {
        entity_id: entityId,
      });
      if (this.getEntityById) {
        this.currentEntity = this.getEntityById;
        this.fetchEntitiesDataForTable(entityId);
      } else {
        this.loading = false;
      }
    },
    async fetchEntitiesDataForTable(entityId) {
      this.loading = true;
      let params = {
        entity_id: entityId,
        template_fields_data: this.currentEntity.templates.map((template) => {
          return {
            template_id: template.template_id,
          };
        }),
        filters: this.filters || [],
      };
      await this.$store.dispatch("entities/fetchEntityRecordsForTable", params);
      if (this.getEntityRecordsForTable) {
        this.allEntityDataList = this.getEntityRecordsForTable.data;
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    getLabel(item, i) {
      let selectedEntity = this.currentEntity ? this.currentEntity : {};
      let name = "data " + i;
      if (selectedEntity && selectedEntity.primaryFields) {
        let primaryField = selectedEntity.primaryFields[0];
        if (
          item?.entityData?.[primaryField?.template_id]?.[primaryField?.key]
        ) {
          name =
            primaryField.inputType == "ENTITY"
              ? item.entityData[primaryField.template_id][
                  primaryField.key + "/name"
                ]
              : primaryField.input_type == "DATE"
              ? moment(
                  item.entityData[primaryField.template_id][primaryField.key]
                ).format(this.getDefaultDateFormat)
              : primaryField.input_type == "DATE_TIME"
              ? moment(
                  item.entityData[primaryField.template_id][primaryField.key]
                ).format(this.getDefaultDateFormat + " HH:mm:ss")
              : item.entityData[primaryField.template_id][primaryField.key];
        }
      }
      return name;
    },
    getRandomKey() {
      return (Math.random() + 1).toString(36).substring(7);
    },
  },
};
</script>
